Vue.component 'minicart',

    template: '#mini-cart-template'

    data: ->
        return {
            YBO: window.YBO
            accepted:false
        }

    computed:
        hasCart: ->
            return !!this.YBO.cart

        cartItemsTotal: ->
            if !this.hasCart
                return 0
            return _.size(this.YBO.cart.items)

        cartItemsTotalPrice: ->
            if !this.hasCart
                return "£0.00"
            return "£#{this.YBO.cart.grandTotal.toFixed(2)}"
