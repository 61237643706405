class Cart

    constructor: ->
        @registerEvents()
        @registerComponent()

    registerEvents: ->
        $(window).bind "pageshow", (event) ->
            if event.originalEvent.persisted
                window.location.reload()

    registerComponent: ->
        @component = new Vue
            el: 'body'

            data:
                cart: YBO.cart

                formData: {}

                countdownInterval: 500
                countdownDiff: 0
                accepted:false

            ready: ->
                if this.hasCart
                    @doCountdown()

            filters:
                countdown: (duration) ->
                    if !duration
                        return '0s'
                    # Add each countdown section to an array so we can use a simple join
                    countDownItems = []
                    if duration.seconds() > 0 or duration.minutes() > 0
                        # We only want minutes if it has them
                        if duration.minutes() > 0
                            countDownItems.push "#{duration.minutes()}m"
                        # We always want seconds
                        countDownItems.push "#{duration.seconds()}s"
                    else
                        window.YBO.cart = false
                        # The time expired, let's show a message
                        swal({
                            title: "Your cart has expired"
                            text:  "Tickets are only reserved for 15 minutes, please re-add them to your cart to checkout."
                            type:  "error"
                        }, ->
                            window.location.replace('/cart/empty')
#                          window.history.back()
#                            window.location.reload()
                        )

                    return countDownItems.join(" ")

            computed:
                hasCart: ->
                    return !!YBO.cart

                cartShouldShow: ->
                    return this.hasCart

                expiredShouldShow: ->
                    return !this.hasCart

                emptyCartShouldShow: ->
                    return !this.hasCart

                grandTotal: ->
                    if !this.hasCart
                        return "0.00"

                    return this.cart.grandTotal.toFixed(2)

            methods:
                displaySeats: (item) ->

                    if (item.tickets[1] && item.tickets[1].seats.length)
                        seats = _.map item.tickets, (ticket) ->
                            if ticket.seats.length
                                return ticket.seats.join ', '

                        return seats.join ', '

                doCountdown: ->
                    if this.countdownDiff < 0
                        return
                    setTimeout(@updateCountdown, this.countdownInterval)

                updateCountdown: ->
                    now = moment()
                    end = moment(this.cart.expires.date)
                    countdown = moment.duration(end.diff(now))

                    this.countdownDiff = countdown
                    @doCountdown()
