class SeatingPlanHelpers

    grid:
        width:  30
        height: 16

    emptySeatingPlan:
        id:   null
        name: 'New Seating Plan'
        areas: {}

    emptySection:
        id:       null
        tier:     null
        rotation: 0
        name:     'New Section'
        x:        0
        y:        0
        type:     'section'
        rows:     []
    oldSeatingPlan: true

    constructor: ->

    generateSection: (area) ->
        emptySection = _.cloneDeep(@emptySection)

        if area
            emptySection = $.extend(true, emptySection, area)
            if area.type != 'stage'
                emptySection.rows = @generateRows(emptySection.rows)
        else
            emptySection.rows = @generateRows()

        return emptySection

    generateRows: (rowsObject = []) ->
        rows = []
        count = @grid.height

        while count > 0
            if rowsObject[count]
                row = rowsObject[count]
                row.seats = @generateSeats(row.seats)
            else
                row =
                    name:   ''
                    seats:  @generateSeats()
                    number: count

                if !this.isEditingExisting
                    row.name = 'abcdefghijklmnopqrstuvwxyz'.charAt(count - 1).toUpperCase()

                row.seats = @generateSeats(rowsObject.seats)

            rows.push(row)
            count--

        rows = rows.reverse()

        return rows

    generateSeats: (seatsObject = []) ->
        console.log('generate seatingPlanHelper seats')
        seats = []
        count = @grid.width

        while count >0
            if seatsObject[count]
                seat = seatsObject[count]
                seat.available = true
                seats.push(seat)
            else
                seat =
                    name:     ''
                    number:   count
                    disabled: false

                seats.push(seat)
            count--

        if(@oldSeatingPlan)
            seats = seats.reverse()
        return seats

    # We need to modify our data so that it is
    # usable in our application
    formatSeatingPlan: (seatingPlan = false) ->
        if !seatingPlan
            seatingPlan = @emptySeatingPlan
            @oldSeatingPlan = false
        console.log(seatingPlan)
        if(seatingPlan.created_at)
            newPlanDate = new Date('12/04/2018')
            thisPlanDate = new Date(seatingPlan.created_at)
            @oldSeatingPlan =  thisPlanDate < newPlanDate


        # We need to add keys to our data so it's
        # easier to stick into the grid. This is awful and i hate it.
        areas = {}
        # Loop the areas so we can format each row
        for singleArea in seatingPlan.areas
            thisArea = singleArea
            maxGridHeight = 0
            maxGridWidth = 0
            if singleArea.type != 'stage'
                # Make sure we have rows
                if singleArea.rows
                    rows = {}
                    # Loop the rows so we can format those too
                    for singleRow in singleArea.rows
                        if singleRow.number > maxGridHeight
                            maxGridHeight = singleRow.number
                        rows["#{singleRow.number}"] = singleRow
                        # Make sure we have seats
                        if singleRow.seats
                            columns = {}
                            # Loop the seats so we can format them too!
                            for singleColumn in singleRow.seats
                                if singleColumn.number > maxGridWidth
                                    maxGridWidth = singleColumn.number
                                columns["#{singleColumn.number}"] = singleColumn
                        rows["#{singleRow.number}"].seats = columns
                    thisArea.rows = rows
            thisArea.grid_width = maxGridWidth
            thisArea.grid_height = maxGridHeight
            areas["#{singleArea.id}"] = thisArea

        for key, singleArea of areas
            if singleArea.type is 'stage'
                hasStage = true
            areas[key] = @generateSection(singleArea)

        if !hasStage
            # Add a stage object
            areas[_.uniqueId()] =
                id:       null
                tier:     null
                rotation: 0
                name:     'Stage'
                type:     'stage'
                x:        0
                y:        0

        # Update our original data with our formatted areas
        seatingPlan.areas = areas

        # Return a copy to prevent any issues with references.
        return $.extend(true, {}, seatingPlan)

    # Just get the data we need to post for storage
    formatForPost: (areas) ->
        formattedAreas = _.map areas, (area) =>
            if area.hasOwnProperty 'rows'
                area.grid_width = 0
                area.rows = _.map area.rows, (row) =>
                    row.seats = _.filter row.seats, (seat) =>
                        return seat.hasOwnProperty('available') && seat.available
                    return row
                area.rows = _.filter area.rows, (row) =>
                    if area.grid_width < row.seats.length
                        area.grid_width = row.seats.length
                    return row.seats && row.seats.length
                area.grid_height = area.rows.length
            return area

        return formattedAreas
