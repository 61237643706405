class BookConfirmation

    constructor: ->
        @registerEvents()
        @registerComponent()

    registerEvents: ->

    registerComponent: ->
        @component = new Vue
            el: 'body'

            data:
                formData: {}

                isSubmitting: false
                formSuccess:  false

            methods:
                submitForm: (e) ->
                    self = this
                    self.isSubmitting = true
                    self.formError = false

                    if self.formError
                        self.isSubmitting = false
                        return
                    $form = $(e.target)

                    $request = $.ajax
                        url:  YBO.urls.send_tickets
                        type: 'POST'
                        data: self.formData

                    $request.done (data, textStatus, jqXHR) ->
                        self.formSuccess = true
                        self.formData = {}
                        # We'll just reload the page to see any changes
                        window.location.reload()

                    $request.fail (jqXHR, textStatus, errorThrown) ->
                        swal('Error', jqXHR.responseJSON.error.exception, 'error')

                    $request.always (jqXHR, textStatus, errorThrown) ->
                        self.isSubmitting = false
